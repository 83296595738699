import React from 'react'
import {graphql} from 'gatsby'

import ReactMarkdown from 'react-markdown'

import Layout from '../components/mainLayout'

export const query = graphql`
  query WebpageQuery($name: String!) {
    strapiWebpage(name: {eq: $name}) {
      strapiId
      name
      content
    }
  }
`
const test = ({data}) => {
  console.log('data', data)
  const webpage = data.strapiWebpage
  return (
    <Layout>
      <div>name {webpage.name}</div>
      <ReactMarkdown source={webpage.content} />
    </Layout>
  )
}

export default test
